<template>
    <defaultSec :title="'旅程执行情况'">
        <section class="route-state-list">
                <div class="search-content" style="display: flex;justify-content: space-between;align-items: center">
                    <el-form :model="formParam" :inline="true" size="mini" class="demo-form-inline">
                        <el-form-item label="选择日期">
                            <el-date-picker
                                v-model="formParam.date"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="initList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
                    <el-table
                        v-loading="loading"
                        size="mini"
                        :data="dataList"
                        border
                        :height="tableHeight"
                        style="width: 100%;margin-top: 0px;text-align: center">
                        <el-table-column width="170px" fixed
                            label="用户旅程">
                            <template slot-scope="scope">
                                <div style="position: relative;padding-bottom: 30px">
                                    <span style="font-size: 14px;font-weight: 600;margin-right: 10px">{{scope.row.routeName}}</span>
                                    <div style="font-size: 10px;font-style: italic">
                                        <i style="color: #55a532" class="el-icon-loading"></i>频率：{{scope.row.cronName}}
                                    </div>
                                    <el-tag @click="onExecRoute(scope.row)" type="success" size="mini" style="cursor:pointer;position: absolute;bottom: 10px;right: 10px">执行</el-tag>

                                </div>

                            </template>
                        </el-table-column>
                        <el-table-column v-for="item in 24" :label="item<11?'0'+(item-1)+':00':(item-1)+':00'" min-width="70">
                            <template slot-scope="scope">
                                <div v-if="scope.row.timeList[item-1].success>0" style="font-size: 12px;font-style: italic">
                                    <span style="color: #55a532">● </span>
                                    {{scope.row.timeList[item-1].success}}
                                </div>
                                <div v-if="scope.row.timeList[item-1].fail>0" style="font-size: 12px;font-style: italic">
                                    <span style="color: #e50011">● </span>
                                    {{scope.row.timeList[item-1].fail}}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </tablePagination>
        </section>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'

export default {
    name: "groupManage",
    data(){
        return {
            loading:false,
            dataList:[],
            total: 0,
            tableHeight:500,
            formParam: {
                pageSize: 10,
                currentPage: 1,
                date:new Date().Format('yyyy-MM-dd')
            },
        }
    },
    components: {defaultSec,tablePagination},

    mounted() {
        this.initList()
        window.onresize = ()=>{
            this.tableHeight = document.querySelector('.default-body').offsetHeight-100
        }
    },
    methods: {
        initList() {
            this.loading = true
            this.$apiGet('lechun-cms/scrmRoute/getRunRecord', this.formParam).then(res => {
                this.dataList = res.map(item=>{
                    let {cronName,routeName,routeId,...timeObjList} = item
                    let timeList = []
                    for(let key in timeObjList){
                        let value= timeObjList[key].split(';')
                        let color = value[1]>0?'#e50011':(value[0]>0?'#55a532':'')
                        timeList.push({time:key.split('h')[1]+':00',success:value[0],fail:value[1],color})
                    }
                    item.timeList = timeList
                    return item
                })
                this.tableHeight = document.querySelector('.default-body').offsetHeight-100
                this.loading = false
            })
        },
        onExecRoute(row){
            this.$apiGet('lechun-cms/scrmRoute/execRouteLik',{routeId: row.routeId}).then(res => {
                this.$message({
                    showClose: true,
                    message: '执行成功',
                    type: 'success'
                });
                this.initList();
            });
        },
        //跳页执行
        handlePage(page){
            this.formParam.currentPage = page;
            this.initList()
        },
    }
}
</script>

<style  lang="scss" rel="stylesheet/scss">

.route-state-list{
    height: 100%;
    p{
        margin: 0;
    }
    .popper-content{
        box-shadow: 0 0 10px 0 rgba(0,0,0,.4)
    }
    .copy-text{
        white-space: pre-line;
        margin: 0;
        line-height: 18px;
        background: #f2f2f2;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 6px;
        max-height: 127px;
        overflow-y:scroll;
        scrollbar-width: none;
    }

    .el-timeline{
        display: flex;
        padding: 20px 0 0 0;
    }
    .el-timeline-item__tail{
        width: 100%;
        height: auto;
        top: 4px;
        border-top: 2px solid #E4E7ED;
    }
    .el-timeline-item__wrapper{
        top: 0;
        padding-left: 0;
        padding-top: 25px;
    }
    .el-timeline-item{
        padding-bottom: 10px;
        flex: 1;
    }
    .el-timeline .el-timeline-item:last-child .el-timeline-item__tail{
        display: block;
    }
    .el-timeline-item__content{
        position: absolute;
        top: -18px;
        left: 20px;
        p{
            font-size: 10px;
            font-style: italic;
        }
    }
}

</style>
